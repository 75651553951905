// 下属成绩
<template>
  <div class="subordinateGrade">
    <div class="subordinateGrade_box margin-auto">
      <div class="business_tag flexs p-l-100">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/microlecture?type=1' }">微课堂</el-breadcrumb-item>
          <el-breadcrumb-item>查看下属成绩</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="subordinateGrade_mian b-c-f">
        <div class="subordinateGrade_head">
          <span>查看下属成绩</span>
        </div>
        <div class="subordinateGrade_mian_con flex p-t-20 p-b-30">
          <ul class="subordinateGrade_ul flexs">
            <li @click="changeGradeIndex(index)" v-for="(item,index) in 5" :key="index" :class="{active:index == gradeIndex}">区域总监</li>
          </ul>
          <div class="subordinateGrade_ipt flexs">
            <div class="subordinateGrade_ipt_con flexs">
              <img src="../../assets/image/microlecture/sousuo.png" alt="">
              <el-input v-model="from.nickname" placeholder="请输入内容"></el-input>
            </div>
            <div class="subordinateGrade_ipt_btn center">搜索</div>
          </div>
        </div>
        <ul class="subordinateGrade_list flex-wrap flex">
          <li v-for="(item,index) in gradeList" :key="index">
            <div class="subordinateGrade_list_main b-b-1 flex p-t-20 p-b-20">
              <div class="subordinateGrade_list_left flexs">
                <div class="subordinateGrade_list_img">
                  <img :src="item.image" alt="">
                </div>
                <div class="subordinateGrade_list_right">
                  <div class="subordinateGrade_list_name">{{ item.name }}</div>
                  <div class="subordinateGrade_list_ul flexs">
                    <div class="subordinateGrade_list_li flexs">
                      <img src="../../assets/image/microlecture/number.png" alt="">
                      <span>工号：{{ item.id }}</span>
                    </div>
                    <div class="subordinateGrade_list_li flexs">
                      <img src="../../assets/image/microlecture/phone.png" alt="">
                      <span>电话：{{ item.mobile }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="subordinateGrade_list_grade flexs">
                <span>成绩：</span>
                <span>{{ item.defen }}</span>
              </div>
            </div>
            <div class="subordinateGrade_list_time p-t-20 p-b-20 flex">
              <span>{{ item.title }}</span>
              <span>{{ item.createtime }}</span>
            </div>
          </li>
        </ul>
        <div class="paging">
          <el-pagination
            background
             @current-change="changePage"
            layout="prev, pager, next, jumper, ->, total"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      from:{
        nickname:'',
        page:1
      },
      gradeIndex:0,//
      gradeList:[],//
      total:0,//总页数,
      input:'',//
    }
  },
  methods:{
    changePage (e) {
      this.from.page = e
      this.getKaoShiLists()
    },
    //切换
    changeGradeIndex (index) {
      this.gradeIndex = index
      this.from.page = 1
      this.getKaoShiLists()
    },
    //获取成绩
    getKaoShiLists () {
      this.$axios.kaoShiLists(this.from).then(res=>{
        this.gradeList = res.data.data
        this.total = res.data.total
      })
    }
  },
  created () {
    this.getKaoShiLists()
  }
}
</script>

<style lang="less" scoped>
.paging {
  padding-top: 0;
}
.subordinateGrade_box {
  max-width: 1400px;
  .subordinateGrade_mian {
    padding: 30px 100px 0 100px;
  }
  .subordinateGrade_ul {
    li {
      color: #333333;
      font-size: 16px;
      margin-right: 60px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
    }
    .active {
      color: #3273F6;
      font-weight: bold;
      position: relative;
      &::before {
        content: '';
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        bottom: -10px;
        width: 18px;
        height: 2px;
        background: #3273F6;
      }
    }
  }
  .subordinateGrade_head {
    span {
      color: #333333;
      font-size: 18px;
      padding-left: 14px;
      position: relative;
      &::before {
        position: absolute;
        content: '';
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 4px;
        height: 20px;
        background: #3273F6;
      }
    }
  }
  .subordinateGrade_ipt_con {
    width: 300px;
    height: 36px;
    padding: 0 12px;
    border: 2px solid #EBEBEB;
    border-right: none;
    img {
      width: 18px;
      height: 18px;
    }
    /deep/ .el-input__inner {
      border: none;
      height: 32px;
    }
  }
  .subordinateGrade_ipt_btn {
    width: 100px;
    height: 36px;
    color: #FFFFFF;
    font-size: 18px;
    cursor: pointer;
    background: #3273F6;
  }
  .subordinateGrade_list {
    li {
      width: 49%;
      padding: 0 20px;
      margin-bottom: 20px;
      background: #FFFFFF;
      box-shadow: 0px 0px 6px 0px rgba(189, 189, 189, 0.16);
      border-radius: 4px;
      .subordinateGrade_list_img {
        img {
          width: 48px;
          height: 48px;
          border-radius: 50%;
        }
        margin-right: 16px;
      }
      .subordinateGrade_list_name {
        color: #333333;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .subordinateGrade_list_li {
        &:first-child {
          margin-right: 40px;
        }
        img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
        span {
          color: #666666;
          font-size: 16px;
        }
      }
      .subordinateGrade_list_grade {
        span {
          color: #666666;
          font-size: 16px;
          &:last-child {
            color: #FF2121;
            font-size: 20px;
          }
        }
      }
      .subordinateGrade_list_time {
        span {
          color: #666666;
          font-size: 16px;
          &:last-child {
            color: #999999;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>